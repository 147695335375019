import React, { useState } from "react";
import { IconButton, Modal, Box, Typography } from "@mui/material";
import {
  Home as HomeIcon,
  HelpOutline as HelpIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 2,
        padding: 1,
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <IconButton onClick={handleHomeClick} aria-label="Go to home">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={handleOpen} aria-label="Help">
        <HelpIcon />
      </IconButton>

      {/* Modal for Help/Explanation */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            outline: "none",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose} aria-label="Close modal">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" component="h2">
            How It Works
          </Typography>
          <Typography sx={{ mt: 2 }}>
            This service allows you to create a list of options.
            <br />
            <br />
            Share the generated link to collaborate on narrowing down choices.
            For example, you pick three items, pass the link to a friend who
            eliminates one and returns it to you. Then you make the final choice
            from the remaining two options.
            <br />
            <br />
            Use the "X" button to eliminate options until only one remains.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Header;
