const apiUrl = "https://i71kb0586l.execute-api.us-west-2.amazonaws.com/prod";

export const createQuickPick = async (choices: string[], name: string) => {
  const requestBody = {
    id: "", // Empty for creation
    name: name,
    choices: choices.reduce((acc, choice) => {
      acc[choice] = false; // Initialize each choice as not eliminated
      return acc;
    }, {} as Record<string, boolean>),
  };

  console.log("requestBody: ", JSON.stringify(requestBody));

  try {
    const response = await fetch(`${apiUrl}/quickpick`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Failed to create quick pick: ${response.status}`);
    }

    const data = await response.json();
    console.log("Quick pick created:", data);
    return await response.json();
  } catch (error) {
    console.error("Error creating quick pick:", error);
  }
};

export const fetchQuickPick = async (id: string) => {
  try {
    // Check if running locally
    if (window.location.hostname === "localhost") {
      // Return mock data for local development
      const mockData = await import("../mock/fetchQuickPickMock.json");
      return mockData;
    }

    // Make the actual API call in production
    const response = await fetch(apiUrl + "/quick-pick", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch quick pick with ID ${id}:`, error);
    throw error;
  }
};

export const eliminateOption = async (id: string, choice: string) => {
  try {
    if (window.location.hostname === "localhost") {
      // Simulate a delay for local development
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log(
            `Mock: Eliminated choice "${choice}" for Quick Pick ID "${id}"`
          );
          resolve(true);
        }, 500);
      });
    }

    const response = await fetch(apiUrl + "/quick-pick", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ choice }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to eliminate choice:", error);
    throw error;
  }
};
