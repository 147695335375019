import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ChoiceForm from "./components/ChoiceForm";
import QuickPickPage from "./components/QuickPickPage";
import Header from "./components/Header";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<ChoiceForm />} />
            <Route path="/:id" element={<QuickPickPage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
