import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { fetchQuickPick, eliminateOption } from "../api/choiceService";
import { useSpring, animated } from "@react-spring/web"; // Animation library

const QuickPickPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState<string>("");
  const [choices, setChoices] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const data = await fetchQuickPick(id);
          setName(data.name);
          setChoices(data.choices);
        } catch (error) {
          console.error("Error fetching quick pick:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [id]);

  const handleEliminate = async (choice: string) => {
    try {
      await eliminateOption(id!, choice);
      setChoices((prevChoices) => ({
        ...prevChoices,
        [choice]: true,
      }));
    } catch (error) {
      console.error("Error eliminating choice:", error);
    }
  };

  const remainingChoices = Object.keys(choices).filter(
    (choice) => !choices[choice]
  );
  const eliminatedChoices = Object.keys(choices).filter(
    (choice) => choices[choice]
  );

  // Animation for the single remaining choice
  const animationProps = useSpring({
    transform: remainingChoices.length === 1 ? "scale(1.5)" : "scale(1)",
    config: { tension: 300, friction: 20 },
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto", padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        {name}
      </Typography>
      {remainingChoices.length === 1 ? (
        <>
          <animated.div style={animationProps}>
            <Typography
              sx={{
                fontSize: 32,
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 2,
                padding: 2,
                border: "2px solid #000",
                borderRadius: "8px",
              }}
            >
              {remainingChoices[0]}
            </Typography>
          </animated.div>
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h6">Eliminated Choices</Typography>
            {eliminatedChoices.map((choice) => (
              <Typography
                key={choice}
                sx={{
                  textAlign: "center",
                  color: "gray",
                  textDecoration: "line-through",
                  marginTop: 1,
                }}
              >
                {choice}
              </Typography>
            ))}
          </Box>
        </>
      ) : (
        Object.keys(choices).map((choice) => (
          <Box
            key={choice}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 1,
              opacity: choices[choice] ? 0.5 : 1,
              textDecoration: choices[choice] ? "line-through" : "none",
            }}
          >
            <Typography>{choice}</Typography>
            {!choices[choice] && (
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => handleEliminate(choice)}
                sx={{ minWidth: 30 }}
              >
                X
              </Button>
            )}
          </Box>
        ))
      )}
    </Box>
  );
};

export default QuickPickPage;
