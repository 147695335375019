import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { createQuickPick } from "../api/choiceService";

// Function to generate a random name if none is provided
const generateRandomName = () => {
  const adjectives = ["Quick", "Lucky", "Bright", "Swift", "Bold"];
  const nouns = ["Pick", "Choice", "Path", "Decision", "Journey"];
  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${randomAdjective} ${randomNoun}`;
};

const ChoiceForm: React.FC = () => {
  const [choices, setChoices] = useState<string[]>(["", "", ""]);
  const [name, setName] = useState<string>(generateRandomName());
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChangeChoice = (index: number, value: string) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleRegenerateName = () => {
    setName(generateRandomName());
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const quickPickName = name.trim() === "" ? generateRandomName() : name;
      const response = await createQuickPick(choices, quickPickName);
      const uuid = response.id;

      // Clear input fields on successful creation
      setChoices(["", "", ""]);
      setName(generateRandomName());
      // Set the success message with a clickable link to copy
      setSuccessMessage(`Quick Pick "${quickPickName}" created! UUID: ${uuid}`);
      setOpenSnackbar(true);

      // Copy the link to clipboard
      const link = `${window.location.origin}/${uuid}`;
      navigator.clipboard.writeText(link);
      console.log("Copied to clipboard:", link);
    } catch (error) {
      console.error("Error creating quick pick:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        maxWidth: 400,
        margin: "0 auto",
        padding: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          label="Quick Pick Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
          placeholder="Enter a name or leave blank for random"
        />
        <IconButton onClick={handleRegenerateName} aria-label="Regenerate name">
          <RefreshIcon />
        </IconButton>
      </Box>
      {choices.map((choice, index) => (
        <TextField
          key={index}
          label={`Option ${index + 1}`}
          value={choice}
          onChange={(e) => handleChangeChoice(index, e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading || choices.some((choice) => choice.trim() === "")}
        sx={{ marginTop: 2 }}
      >
        {loading ? "Creating..." : "Create"}
      </Button>

      {/* Snackbar for success message */}
      {successMessage && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage} <br />
            Link copied to clipboard!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ChoiceForm;
